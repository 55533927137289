import { checkBrEnv } from './helpers/checkBrEnv';
import { sendToQueue } from './helpers/brEventQueue';
import { BloomreachAddToCartEvent, BloomreachSearchEvent } from './types/bloomreachPixelTypes';

type BREvent = 'cart' | 'suggest';
type BRActionType = 'click-add' | 'submit';

/**
 * We want to simply send the tracking data to bloom reach. These are specifically event pixel events.
 * @param brEvent
 * @param brActionType
 * @param data
 * @returns
 */
const sendBloomreachEventPixel = (brEvent: BREvent, brActionType: BRActionType, data: BloomreachAddToCartEvent | BloomreachSearchEvent) => {
    const isBloomreachEnabledInEnv = checkBrEnv();
    if (!isBloomreachEnabledInEnv) return;
    if (typeof window === 'undefined') return;

    const eventToRun = () => {
        window.BrTrk?.getTracker().logEvent(brEvent, brActionType, { ...data });
    };

    // if the brtrk script hasnt loaded yet the we need to send it to a queue to process
    if (!window.BrTrk) {
        sendToQueue(eventToRun);
        return;
    }

    eventToRun();
};

export const bloomreachPixelSendAddToCartEvent = (data: Omit<BloomreachAddToCartEvent, 'etype' | 'group'>) =>
    sendBloomreachEventPixel('cart', 'click-add', {
        ...data,
        etype: 'click-add',
        group: 'cart',
    });

export const bloomreachPixelSendSearchEvent = (data: Omit<BloomreachSearchEvent, 'catalogs' | 'etype' | 'group'>) =>
    data.q &&
    sendBloomreachEventPixel('suggest', 'submit', {
        ...data,
        etype: 'submit',
        group: 'suggest',
        catalogs: [{ name: 'theindependentpharmacy' }],
    });
