import React from 'react';

import dynamic from 'next/dynamic';
import Tag from '../_ui/_blocks/Tag/Tag';

import styles from './TagCloud.module.css';

const Link = dynamic(() => import('next/link'));

export interface TagItem {
    name: string;
    href?: string;
}

type TagProps = React.ComponentProps<typeof Tag>;

interface Props<T> {
    tags: T[];
    color?: TagProps['color'];
    align?: 'center' | 'left';
    onClick?: (tag: T) => void;
}

const TagCloud = <T extends TagItem>({ tags, onClick, color = 'success', align = 'center' }: Props<T>) => (
    <div data-testid="tag-cloud" className={`${styles.cloud} ${align === 'center' ? 'justify-center' : ''}`}>
        {tags.map((tag) => {
            const handleClick = (!tag.href && onClick) ? () => onClick(tag) : undefined;
            const ButtonTag = tag.href ? Link : 'button';

            return (
                <ButtonTag
                    key={tag.name}
                    type={ButtonTag === 'button' ? 'button' : undefined}
                    onClick={handleClick}
                    href={tag.href as string}
                >
                    <Tag size="large" color={color} className={styles.tag}>
                        {tag.name}
                    </Tag>
                </ButtonTag>
            );
        })}
    </div>
);

export default TagCloud;
