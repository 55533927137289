'use client';

import React, { useState } from 'react';
import NextLink from 'next/link';

type NextLinkProps = React.ComponentProps<typeof NextLink>;

interface Props extends Omit<NextLinkProps, 'prefetch'> {
    prefetch?: 'enable' | 'disable' | 'hover' | 'default';
};

/**
 * Calculates from our custom values below what the actual value should be for the NextJS Link component.
 */
const calculatePrefetchValue = (prefetch: Props['prefetch']): NextLinkProps['prefetch'] => {
    // "hover" is handled in the component itself.
    switch(prefetch) {
        case 'enable':
            return true;
        case 'disable':
            return false;
        default:
            return null;
    }
}

/**
 * Custom logic on top of the standard NextJS Link component.
 * The main change is that prefetch now happens on hover by default. This was added because prefetching a
 * page was also calling all the API endpoints on that page, causing the API usage to go through the roof.
 *
 * @see https://nextjs.org/docs/app/api-reference/components/link
 */
const Link = ({ prefetch = 'default', onMouseOver, ...rest }: Props) => {
    const [hasBeenHoveredOver, setHasBeenHoveredOver] = useState<false | null>(false);

    let handleMouseOver = onMouseOver;

    if (prefetch === 'hover' && hasBeenHoveredOver === false) {
        handleMouseOver = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            setHasBeenHoveredOver(null);

            if (onMouseOver) {
                onMouseOver(e);
            }
        };
    }

    const shouldPrefetch = prefetch === 'hover' ? hasBeenHoveredOver : calculatePrefetchValue(prefetch);

    return <NextLink prefetch={shouldPrefetch} onMouseOver={handleMouseOver} {...rest} />;
};

export default Link;
