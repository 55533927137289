import { useEffect, useRef } from 'react';

/**
 * A hook to throttle function calls, if too many come in at once then only run the final one.
 * @param callback Function to by called when running setInterval function;
 * @param delay time to delay before running the function;
 * @return void;
 */
const useThrottledEffect = (callback, dependencies = [], delay = 250) => {
    const timeoutRef = useRef();

    // useEffect to update poll when params change;
    useEffect(() => {
        let callbackVal = null; // this is so we can use the hook like useEffect where the function accepts a return function that closes on mount. We call in in the returned function below.

        // Set time function to run callback function provided;
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            callbackVal = callback();
        }, delay);

        // Clear timeout on dismount & change;
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            if (typeof callbackVal === 'function') callbackVal();
        };
    }, dependencies); // eslint-disable-line
};

export default useThrottledEffect;
