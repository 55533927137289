/* eslint-disable react/button-has-type */
import React from 'react';
import clx from 'classnames';

export enum Variants {
    primary = 'button-primary',
    secondary = 'button-secondary',
    tertiary = 'button-tertiary',
    destructive = 'button-destructive',
    demoted = 'button-demoted',
    none = '',
}

export enum Sizes {
    micro = 'button-size-micro',
    small = 'button-size-small',
    default = 'button-size-default',
    large = 'button-size-large',
    none = '',
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    variant?: keyof typeof Variants;
    size?: keyof typeof Sizes;
    type?: 'button' | 'submit' | 'reset';
    className?: string; // Should only be used for layout purposes e.g. flex or inline-block.
}

const Button = ({ children, type = 'button', variant = 'primary', size = 'default', className, disabled, ...rest }: Props) => {
    const classNames = clx('button', Variants[variant], Sizes[size], className);

    return (
        <button type={type} className={classNames} disabled={disabled} {...rest}>
            {children}
        </button>
    );
};

export default Button;
