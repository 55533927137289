'use client';

import { useRef, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import useUrlHash from './useUrlHash';
import useUrlSearchParams from './useUrlSearchParams';

type UsePathnameReturnType = ReturnType<typeof usePathname>;

type Callback = (pathName: UsePathnameReturnType) => void

interface Options {
    trackHash?: boolean;
    trackSearchParams?: boolean;
}

// Optional extras to track.
const defaultOptions = {
    // Tracks the hash: /example#anchor
    trackHash: false,
    // Tracks the search params: /example?show_values=1
    trackSearchParams: false,
}

/**
 * Runs a callback function when the page path changes. Uses Nextjs usePathname hook. Created this as we can't
 * use next/router events, see link below.
 * @see https://nextjs.org/docs/app/api-reference/functions/use-pathname
 * @see https://nextjs.org/docs/messages/next-router-not-mounted
 */
const useOnNavigate = (callback: Callback, options: Options = {}) => {
    const config = {
        ...defaultOptions,
        ...options,
    };

    const pathName = usePathname();

    // Gets the URL search params to tack. Not using NextJS useSearchParams, see description in
    // useUrlSearchParams as to why.
    const searchParams = config.trackSearchParams ? useUrlSearchParams() : '';

    // Gets the URL hash to tack.
    const hash = config.trackHash ? useUrlHash() : '';

    // This ref is used to compare the previous path with a new path to ensure it has changed.
    const currentUrlPath = useRef(`${pathName}${searchParams}${hash}`);

    useEffect(() => {
        const newUrlPath = `${pathName}${searchParams}${hash}`;

        // This "if" allows us to skip the first render and makes sure the callback is only called when the
        // path changes.
        if (currentUrlPath.current === newUrlPath) {
            return;
        }

        currentUrlPath.current = newUrlPath;
        callback(newUrlPath);
    }, [pathName, callback, hash, searchParams]);
};

export default useOnNavigate;
