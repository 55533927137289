import React from 'react';
import clx from 'classnames';

import styles from './Tag.module.css';

const colors = {
    regular: '',
    bestSeller: styles.bestSeller,
    bundlePack: styles.bundlePack,
    error: styles.error,
    warning: styles.warning,
    success: styles.success,
    info: styles.info,
    specialOffer: styles.specialOffer,
    newProduct: styles.newProduct,
    multiBuyOffer: styles.multiBuy,
    newPackaging: styles.newPackaging,
    sale: styles.sale,
    discountCode: styles.discountCode,
    priceSaving: styles.priceSaving,
}

const sizes = {
    none: '',
    medium: styles.medium,
    large: styles.large,
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    as?: 'span' | 'div';
    size?: keyof typeof sizes;
    color?: keyof typeof colors;
}

const Tag = ({ as: Tag = 'span', size = 'medium', color = 'regular', className, ...rest }: Props) => {
    const classNames = clx(className, styles.tag, sizes[size], colors[color]);
    return <Tag className={classNames} {...rest} />;
};

export default Tag;
