import { useReducer, useMemo, useCallback } from 'react';
import algoliasearch from 'algoliasearch/lite';
import useThrottleEffect from './useThrottledEffect';
import dispatchGoogleAnalyticsEvent, { gaEvents, gaFormatters } from '../modules/analytics/google/dispatchGoogleAnalyticsEvent';

const NEXT_PUBLIC_ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || process.env.ALGOLIA_APP_ID;
const NEXT_PUBLIC_ALGOLIA_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || process.env.ALGOLIA_APP_KEY;

if (!NEXT_PUBLIC_ALGOLIA_APP_ID || !NEXT_PUBLIC_ALGOLIA_API_KEY) {
    throw Error('NEXT_PUBLIC_ALGOLIA_APP_ID and NEXT_PUBLIC_ALGOLIA_API_KEY need to exist in the environment variables. ');
}

/**
 * This is a pretty standard pattern however inspired
 * from https://www.robinwieruch.de/react-hooks-fetch-data and
 * reworked to use our service pattern.
 */

/**
 * The reducer that deals with the data being flung about in the hook.
 * @param {*} state
 * @param {*} action
 */
const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case 'ALGOLIA_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'ALGOLIA_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                algoliaResponse: action.payload,
            };
        case 'ALGOLIA_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload,
            };
        case 'ALGOLIA_RESET':
            return {
                isLoading: false,
                isError: false,
                error: undefined,
                algoliaResponse: undefined,
            };
        default:
            throw new Error();
    }
};

const DEFAULT_ALGOLIA_CONFIG = {
    minCharacters: 3,
    page: 0,
    enableAnalytics: true,
};

const DEFAULT_ALGOLIA_OPTIONS = {
    hitsPerPage: 50,
};

/**
 * This is a hook that takes our search term and our indexName and allows us to
 * get algolia results based upon that.
 * ensure the environement variables are set so the client can actually connect to the account
 * @param {string} query - the search term to search with.
 * @param {string} indexName - name of the index to search on
 * @param {object} config - configuration options for the hook
 * @param {object} searchOptions - we dont include page on this as we force this to only be listened to on the initial load of the component
 */
const useAlgoliaSearch = (query, indexName, config = {}, searchOptions = DEFAULT_ALGOLIA_OPTIONS) => {
    const algoliaConfig = { ...DEFAULT_ALGOLIA_CONFIG, ...config };
    const { page, minCharacters, enableAnalytics } = algoliaConfig;

    const client = useMemo(() => algoliasearch(NEXT_PUBLIC_ALGOLIA_APP_ID, NEXT_PUBLIC_ALGOLIA_API_KEY), []);
    const options = useMemo(() => searchOptions, []); // eslint-disable-line
    const algoliaIndex = useMemo(() => client.initIndex(indexName), [client, indexName]);

    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        error: undefined,
        algoliaResponse: undefined,
    });

    /**
     * Set up a function that redeclares itself only when the index or search term changes.
     */
    const fetchData = useCallback(
        async (didCancel = false) => {
            dispatch({ type: 'ALGOLIA_INIT' });

            try {
                const algoliaResponse = await algoliaIndex.search(query, { ...options, page });
                if (!didCancel) {
                    dispatch({ type: 'ALGOLIA_SUCCESS', payload: algoliaResponse });
                }
            } catch (error) {
                if (!didCancel) dispatch({ type: 'ALGOLIA_FAILURE', payload: error });
            }

            if (enableAnalytics) {
                dispatchGoogleAnalyticsEvent(gaEvents.Search, gaFormatters.search(query, page + 1));
            }
        },
        [algoliaIndex, options, page, query, enableAnalytics]
    );

    useThrottleEffect(
        () => {
            let didCancel = false;

            if (query.length >= minCharacters) fetchData(didCancel);

            if (query.length === 0) dispatch({ type: 'ALGOLIA_RESET' });

            return () => {
                didCancel = true;
            };
        },
        [fetchData, minCharacters, query],
        1000
    );

    return [state, fetchData, algoliaIndex];
};

export default useAlgoliaSearch;
