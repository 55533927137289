import { BLOOMREACH_PIXEL_ACCOUNT_ID, BLOOMREACH_PIXEL_DOMAIN_KEY, TRACKING_ENABLED, isBloomreachEnabled } from '../../../../../config/config';

/**
 * This is a function to check we have tracking enabled (this is for other things as well as bloomreach);
 * And also that bloomreach is enabled and we have the pixel account id and domain key set in the env.
 * @returns
 */
export const checkBrEnv = () => {
    const bloomreachEnabled = TRACKING_ENABLED && isBloomreachEnabled();
    if (!bloomreachEnabled) return false;
    if (!BLOOMREACH_PIXEL_ACCOUNT_ID)
        throw new Error('Bloomreach Pixel Account ID is not set but bloomreach is enabled.');
    if (!BLOOMREACH_PIXEL_DOMAIN_KEY)
        throw new Error('Bloomreach Pixel Domain Key is not set but bloomreach is enabled.');

    return true;
};
