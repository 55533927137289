import { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'next/navigation';

/**
 * Used to track when the URL hash changes.
 */
const useUrlHash = () => {
    const params = useParams();
    const [hash, setHash] = useState(typeof window !== 'undefined' ? window.location.hash : '');
    const currentHash = useRef(hash);

    const updateHash = useCallback(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const newHash = window.location.hash || '';
        if (currentHash.current === newHash) {
            return;
        }

        currentHash.current = newHash;
        setHash(newHash);
    }, []);

    // If the NextJS params change, update the hash state.
    useEffect(() => {
        updateHash();
    }, [params, updateHash]);

    // If a native javascript event is triggered, update the hash.
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener('hashchange', updateHash);

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('hashchange', updateHash);
        };
    }, [updateHash]);

    return hash;
};

export default useUrlHash;
