'use client';

import React, { useCallback, useRef } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import clx from 'classnames';
import Image from 'next/image';

import { useSearchContext } from '../../../data/context/searchContext';

import Button from '../../_ui/_blocks/Buttons/Button/Button';
import Icon from '../../_ui/_blocks/Icon/Icon';

import styles from './SearchInput.module.css';

type ImageProps = React.ComponentProps<typeof Image>;

interface Props extends React.InputHTMLAttributes<HTMLInputElement>, Pick<ImageProps, 'loading'> {
    variant?: 'bordered' | 'shadow';
    shiftIconRight?: boolean;
    enableButton?: boolean;
    className?: string;
    placeholder?: string;
    invokesOverlay?: boolean;
    mobileStack?: boolean;
    clear?: boolean;
}

/**
 * Search input
 * This search input depends on the search text and directly interacts with the algolia API via the
 * searchTerm being set. This also allows us to set the overlay to open depending on props being passed in.
 *
 * TODO: @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/search
 *
 * @see https://www.figma.com/design/HB2vMGpmieuLtPlJUEfFXi/Hippo-UI-kit-3.1?node-id=153-14695&node-type=canvas&t=QKENVejOfhojh8PL-0
 */
const SearchInput = ({
    variant = 'bordered',
    shiftIconRight,
    enableButton,
    className,
    invokesOverlay,
    placeholder = 'Find a treatment...',
    loading = 'lazy',
    mobileStack,
    clear = false,
    ...rest
}: Props) => {
    const { searchTerm, setSearchTerm, setShowOverlay } = useSearchContext();
    const input = useRef<HTMLInputElement>(null);

    const router = useRouter();

    const clearSearch = useCallback(() => {
        setSearchTerm('');
        setShowOverlay(false);
    }, [setSearchTerm]);

    const pathName = usePathname();

    const isSearchPage = pathName?.includes('/search-results');

    // Runs when the input is typed in.
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;

        setSearchTerm(value || '');

        if (invokesOverlay && isSearchPage === false) {
            setShowOverlay(value.length > 2);
        }
    }, [setSearchTerm, setShowOverlay, invokesOverlay, isSearchPage]);

    // Navigates user to the search page.
    const handleNavigateToSearchPage = () => {
        router.push(`/search-results?searchTerm=${searchTerm}`);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleNavigateToSearchPage();
    }

    // On pressing enter, take the user to the search page.
    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter'  || isSearchPage) {
            return;
        }

        e.preventDefault();
        handleNavigateToSearchPage();
    }

    const handleFocusOnInput = () => {
        input.current?.focus();
    }

    const buttonEnabled = enableButton && !shiftIconRight;

    const wrapperClasses = clx(
        mobileStack ? styles.wrapperMobileStack : styles.wrapper,
        buttonEnabled ? styles.hasButton : '',
        variant === 'bordered' ? styles.wrapperBordered : '',
        variant === 'shadow' ? styles.wrapperShadow : '',
    );

    return (
        <div className={`@container ${className || ''}`}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
            <form className={wrapperClasses} onSubmit={handleSubmit} onClick={handleFocusOnInput} role="search">
                <div className={`flex flex-row gap-075 flex-grow ${mobileStack ? 'px-050' : ''}`}>
                    <Icon
                        className={`flex-grow-0 flex-shrink-0 pointer-events-none ${shiftIconRight ? styles.searchIconRight : ''}`}
                        icon="search-light"
                        aria-hidden="true"
                        loading={loading}
                        alt=""
                    />
                    <input
                        {...rest}
                        value={searchTerm}
                        className={`${styles.input} ${shiftIconRight ? 'order-1' : ''} ${mobileStack ? 'py-100 @[375px]:py-0' : ''}`}
                        placeholder={placeholder}
                        type="search"
                        onChange={handleChange}
                        ref={input}
                        onKeyDown={handleInputKeyDown}
                    />
                </div>
                {clear && (
                    <button
                        type="button"
                        className="flex-shrink-0 my-050 mr-100 p-050"
                        onClick={clearSearch}
                        title="Click to close search"
                    >
                        <Icon icon="cross-light" alt="cross icon" size="xsmall" />
                    </button>
                )}
                {buttonEnabled ? (
                    <Button
                        title="Search for results"
                        className={mobileStack ? styles.mobileStackButton : styles.button}
                        size="small"
                        type="submit"
                    >
                        Search
                    </Button>
                ) : null}
            </form>
        </div>
    );
};

export default SearchInput;
