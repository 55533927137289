'use client';

import React, { createContext, useState, useContext, useMemo, useEffect, useRef, useCallback } from 'react';
import uniqBy from 'lodash.uniqby';

import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useThrottledEffect from '../../hooks/useThrottledEffect';
import { bloomreachPixelSendSearchEvent } from '../../modules/analytics/bloomreach/BloomreachPixel/sendBloomreachEventTrackingEvent';
import useOnNavigate from '../../hooks/useOnNavigate';


const indexPrefix = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX;

const SearchContext = createContext();

const searchEventThrottleTime = 1500;

/**
 * HELPER
 * Specific to our algolia implementation this retrieves the algolia listing for treatments and produces
 * conditions from it.
 * @param {arrayOfAlgoliaHits} algoliaTreatments
 */
const selectConditionsFromTreatments = (algoliaTreatments) => {
    let conditions;

    if (algoliaTreatments) {
        conditions = [];
        algoliaTreatments.forEach((t) => {
            conditions.push({
                condition: t.condition,
                condition_url: t.condition_url,
                condition_description: t.condition_description,
            });

            if (t.secondaryConditions) {
                t.secondaryConditions.forEach((c) => {
                    conditions.push({
                        condition: c.condition,
                        condition_url: c.condition_url,
                        condition_description: c.condition_description,
                    });
                });
            }
        });
    }

    return conditions && uniqBy(conditions, (d) => d.condition);
};

/**
 * This provider handles all of our search state. Wrapping a new provider will create a new scoped-state.
 * Meaning you can have multiple individual searches on a page. Useful for in page results vs overlay results.
 */
export const SearchProvider = ({ defaultSearchTerm = '', ...props }) => {
    const [searchTerm, setSearchTerm] = useState(() => {
        if (typeof window === 'undefined') return '';
        return new URLSearchParams(window.location.search).get('searchTerm') || defaultSearchTerm;
    });
    const [showOverlay, setShowOverlay] = useState(false);

    const handleNavigationChange = useCallback(() => {
        const url = new URL(window.location.href);
        const { pathname, search } = url;
        const params = new URLSearchParams(search);

        // If we're on the search page and there's a search term.
        if (pathname.includes('search-results') && params.get('searchTerm')) {
            setSearchTerm(params.get('searchTerm'));
            return;
        }

        setSearchTerm('');
    });

    useOnNavigate(handleNavigationChange);

    /** Invoke our algolia search hook to listen to our search terms */
    const [data] = useAlgoliaSearch(
        searchTerm,
        `${indexPrefix}_PRODUCTS`,
        {},
        {
            attributesToSnippet: 'description:20',
            hitsPerPage: 100,
        }
    );

    // Maintains search term in state with the one stored in the URL.
    useThrottledEffect(
        () => {
            if (typeof window === 'undefined') return;
            const params = new URLSearchParams(window.location.search);

            if (params.get('searchTerm') === searchTerm) return;

            if (searchTerm) {
                params.set('searchTerm', searchTerm);
            } else {
                params.delete('searchTerm');
            }

            let stringParams = params.toString();
            stringParams = stringParams ? `?${stringParams}` : '';

            window.history.replaceState(null, '', stringParams);
        },
        [searchTerm],
        1000
    );

    // We want the initial search event to be skipped if the user lands on the search results page.
    // This is a huge work around for the bloomreach pixel workflow. We only want the search event to be
    // called when the user types and not when search results are loaded for the first time on the page.
    const shouldSkipInitialSearchEvent = useRef(
        (typeof window !== 'undefined' ? window.location.pathname : '') === '/search-results'
    );
    useThrottledEffect(() => {
        if (shouldSkipInitialSearchEvent.current) {
            shouldSkipInitialSearchEvent.current = false;
            return;
        }
        if (searchTerm) {
            bloomreachPixelSendSearchEvent({
                q: searchTerm,
            });
        }
    }, [searchTerm], searchEventThrottleTime);

    // Listen to the searchTerm.
    useEffect(() => {
        // Close the overlay if we hit 0 length.
        if (searchTerm.length === 0) {
            setShowOverlay(false);
        }

        // Reset the current page if searchTerm Changes.
    }, [searchTerm]);

    // Get the treatments and the conditions. The conditions are based on the linked conditions on the treatments.
    const treatments = data.algoliaResponse ? data.algoliaResponse.hits : undefined;
    const conditions = useMemo(() => selectConditionsFromTreatments(treatments), [treatments]);

    // TODO: We need to add a use memo to this to prevent re-renders (contextValueRefactor, Ticket: 862jfuy9w).
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
        isLoading: data.isLoading,
        searchEventThrottleTime,

        searchTerm,
        setSearchTerm,

        treatments,
        conditions,

        showOverlay,
        setShowOverlay,
    };

    return <SearchContext.Provider {...props} value={value} />;
};

export const SearchConsumer = ({ ...props }) => <SearchContext.Consumer {...props} />;

/**
 * Hook to be used in components that will allow us access to the values passed into the search provider above.
 * const { searchTerm } = useSearchContext()
 */
export const useSearchContext = () => {
    const context = useContext(SearchContext);

    if (context === undefined) {
        throw new Error('useSearchContext must be used within a SearchProvider');
    }
    return context;
};
