import { useState, useEffect, useRef } from 'react';
import { useParams } from 'next/navigation';

/**
 * Used to track when the URL search params change. We could use useSearchParams from NextJS but that can
 * only be used client side (unless wrapped in React <Suspense />) for some reason and will cause this error:
 * @see https://nextjs.org/docs/messages/deopted-into-client-rendering
 */
const useUrlSearchParams = () => {
    const params = useParams();
    const [searchParams, setSearchParams] = useState(typeof window !== 'undefined' ? window.location.search : '');
    const currentSearchParams = useRef(searchParams);

    // If the NextJS params change, update the search params state.
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const newSearchParams = window.location.search || '';
        if (currentSearchParams.current === newSearchParams) {
            return;
        }

        currentSearchParams.current = newSearchParams;
        setSearchParams(newSearchParams);
    }, [params]);

    return searchParams;
};

export default useUrlSearchParams;
