/**
 * Sometimes when we send an event the script hasnt loaded into the window yet.
 * So we wait for the script to load in and then we process the queue.
 * This send to queue function will queue up the function to run.
 * @param functionToRun
 * @returns
 */
export const sendToQueue = (functionToRun: () => any) => {
    if (typeof window === 'undefined') return;
    if (!window.brEventQueue) window.brEventQueue = [];
    window.brEventQueue.push(functionToRun);
};

/**
 * On load of the bloomreach script we process the event queue.
 * @returns
 */
export const processQueue = () => {
    if (typeof window === 'undefined') return;
    if (!window.brEventQueue) return;

    while (window.brEventQueue.length) {
        const functionToRun = window.brEventQueue.shift();
        if (functionToRun) functionToRun();
    }
};
